import DriveProjectAttribute from "./driveProjectAttribute";

export default class ProjectInfo {
  id?: string;
  projectId?: string;
  name?: string = "";
  browserUUID: string = "";
  createdDate: Date = null;
  createdBy: string = "";
  lastUpdatedDate: Date = null;
  lastModifiedBy: string = "";
  parentProjectId?: string = "";
  parentProjectName?: string = "";
  projectDescription: DriveProjectAttribute = new DriveProjectAttribute();
  planner: DriveProjectAttribute = new DriveProjectAttribute();
  standard: DriveProjectAttribute = new DriveProjectAttribute();
  location: DriveProjectAttribute = new DriveProjectAttribute();
  client: DriveProjectAttribute = new DriveProjectAttribute();
  designOffice: DriveProjectAttribute = new DriveProjectAttribute();
  country: DriveProjectAttribute = new DriveProjectAttribute();
  language: DriveProjectAttribute = new DriveProjectAttribute();
  comment: DriveProjectAttribute = new DriveProjectAttribute();
  configurationUrl: string = "";
  autoSaveUrl: string = "";
  latestConfig: string = "";
  isLocked: boolean;
  truncatedProperties?: string;
}
