import { NONE_TYPE } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import axios, { AxiosError } from "axios";
import { CookieService } from "ngx-cookie-service";
import { removeSessionCookie } from "../../../lib/utils";
import AxiosClient from "../client/AxiosClient";
import { ResultType } from "../models/Result";
import { ToasterService } from "../toaster.service";
import { serviceFactory } from "./servicefactory/serviceFactory";
import serviceUrlConstants from "./serviceUrlConstants";
@Injectable({
  providedIn: "root",
})
export class LogoutService {
  constructor(
    public toasterService: ToasterService,
    public cookieService: CookieService,
    public router: Router,
    private authService: AuthService
  ) {}

  async logout() {
    const url = serviceUrlConstants.getLogoutUrl();
    const header = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage.i18nextLng,
      },
    };
    await AxiosClient.post(url, header, NONE_TYPE)
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            removeSessionCookie("JSESSIONID");
            this.authService.logout({
              openUrl: () => {
                this.router.navigate(["/login"], {
                  queryParams: { isNotAuthenticated: true },
                });
              },
            });
            break;
          }
          case ResultType.FAIL: {
            this.toasterService.showError(response);
          }
        }
      })
      .catch((error: AxiosError) => {
        this.toasterService.showError(error.message);
      });
  }
}
