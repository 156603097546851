<form
  class="d-flex h-100 flex-column justify-content-around overflow-hidden p-4"
  [formGroup]="markForm"
  (ngSubmit)="onSubmit()"
>
  <div class="d-flex justify-content-between">
    <h3>{{ "CurrentTimeDialog_title" | translate }}</h3>
    <button
      ui-button
      size="icon-small"
      variant="tertiary"
      (click)="onCancel()"
      type="button"
    >
      <app-close-icon></app-close-icon>
    </button>
  </div>
  <div>
    <div class="flex gap-3">
      <div class="flex flex-col flex-grow">
        <label for="current">{{ xLabel }}</label>
        <input
          appTooltip
          placement="top"
          tooltip="{{ tooltipXVal }}"
          appLocalize
          ui-input
          id="current"
          data-testid="currentValue"
          formControlName="currentValue"
          (input)="updatedTime($event.target.value)"
        />
      </div>
      <div class="flex flex-col flex-grow">
        <label for="time">{{ yLabel }}</label>
        <input
          appTooltip
          placement="top"
          tooltip="{{ tooltipYVal }}"
          appLocalize
          ui-input
          id="time"
          type="text"
          class="w-100"
          data-testid="timeValue"
          formControlName="timeValue"
          (input)="updateCurrent($event.target.value)"
        />
      </div>
      @if (data.isMarkOnSeriesData) {
        <div class="flex flex-col justify-center items-end w-8">
          <label for="isLinked" class="invisible">Link/Unlink</label>
          <button
            ui-button
            id="isLinked"
            variant="tertiary"
            size="icon-small"
            type="button"
            role="switch"
            [attr.aria-checked]="markForm.value.isLinked"
            (click)="
              this.markForm.patchValue({ isLinked: !markForm.value.isLinked })
            "
          >
            @if (markForm.value.isLinked) {
              <app-link-icon [type]="'link'"></app-link-icon>
            } @else {
              <app-link-icon [type]="'unlink'"></app-link-icon>
            }
          </button>
        </div>
      }
    </div>
  </div>
  <div class="d-flex flex-row-reverse gap-3">
    <button
      ui-button
      type="submit"
      data-testid="addMarkOk"
      [disabled]="markForm.invalid"
    >
      {{ "Button_add" | translate }}
    </button>
    <button ui-button variant="secondary" (click)="onCancel()" type="button">
      {{ "CANCEL" | translate }}
    </button>
  </div>
</form>
