import BaseModel from "../../models/devices/BaseModel";
import { ChartType } from "../../models/constants/chartType";
import { Constants } from "../../models/constants/Constants";
import { CurveDetails } from "../../models/marks/curveDetails";
import CurveTracingModel from "../../models/marks/CurveTracingModel";
import MarkModel from "../../models/marks/MarkModel";
import ProductModel from "../../models/devices/ProductModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import LineConfigurationModel from "../../models/devices/line-config/LineConfigurationModel";
import { MarkConstants } from "../../models/constants/MarkConstants";
import { toSuperScript } from "../../../lib/super-script";

export default class HighChartHelper {
  onMouseMove(
    mouseEvent,
    isShowDefaultText: boolean,
    chartData,
    curveTracingModel: CurveTracingModel,
    chartType: ChartType
  ) {
    if (
      !isShowDefaultText &&
      chartData &&
      chartData.ref &&
      chartData.ref.series.length > 0
    ) {
      let highChart = document.getElementById("high-chart");
      let parentDiv = document.getElementById("highchart");
      let cursor = document.getElementById("cursor");
      let tooltip = document.getElementById("tooltip");
      curveTracingModel.isToolTipVisible = false;
      let xValue, yValue;
      let xMin = Math.pow(10, chartData.ref.xAxis[0].min);
      let xMax = Math.pow(10, chartData.ref.xAxis[0].max);
      let yMin = Math.pow(10, chartData.ref.yAxis[0].min);
      let yMax = Math.pow(10, chartData.ref.yAxis[0].max);

      let legendX = chartData.ref.legend.group.translateX;
      let legendY = chartData.ref.legend.group.translateY;
      let legendHeight = chartData.ref.legend.group.getBBox().height;
      let legendWidth = chartData.ref.legend.group.getBBox().width;

      xValue = parseFloat(
        parseFloat(chartData.ref.xAxis[0].toValue(mouseEvent.offsetX)).toFixed(
          3
        )
      );
      yValue = parseFloat(
        parseFloat(chartData.ref.yAxis[0].toValue(mouseEvent.offsetY)).toFixed(
          3
        )
      );
      curveTracingModel.xValue = xValue;
      curveTracingModel.yValue = yValue;
      xValue >= 1000
        ? (curveTracingModel.formattedXValue =
            (xValue / 1000).toFixed(3) + " k")
        : (curveTracingModel.formattedXValue = xValue);
      if (chartType !== ChartType.TRIP) {
        yValue >= 1000
          ? (curveTracingModel.formattedYValue =
              (yValue / 1000).toFixed(3) + " k")
          : (curveTracingModel.formattedYValue = yValue);
      } else {
        curveTracingModel.formattedYValue = yValue;
      }
      curveTracingModel.formattedXValue =
        curveTracingModel.formattedXValue.toLocaleString(
          localStorage.getItem("i18nextLng")
        );
      curveTracingModel.formattedYValue =
        curveTracingModel.formattedYValue.toLocaleString(
          localStorage.getItem("i18nextLng")
        );
      //TO check if the mouse is inside the chart area
      if (
        mouseEvent.offsetX > Constants.HIGHCHART_OFFSETX &&
        Constants.HIGHCHART_OFFSETY <= mouseEvent.offsetY &&
        mouseEvent.offsetY <=
          chartData.ref.xAxis[0].height + Constants.HIGHCHART_OFFSETY &&
        xMin <= xValue &&
        xValue <= xMax &&
        yMin <= yValue &&
        yValue <= yMax
      ) {
        //TO check if the mouse is over the legend area
        if (
          legendX === undefined ||
          legendY === undefined ||
          mouseEvent.offsetX < legendX ||
          mouseEvent.offsetY > legendY + legendHeight
        ) {
          if (tooltip) {
            curveTracingModel.isToolTipVisible = true;
            let snapPoint;
            if (!curveTracingModel.prevSnapPoint) {
              snapPoint = this.formDataToGetSnapPoint(
                mouseEvent.offsetX,
                mouseEvent.offsetY,
                chartData
              );
              curveTracingModel.prevSnapPoint = snapPoint;
            }
            let diffX, diffY;
            if (
              curveTracingModel.prevChartX === undefined &&
              curveTracingModel.prevChartY === undefined
            ) {
              curveTracingModel.prevChartX = mouseEvent.offsetX;
              curveTracingModel.prevChartY = mouseEvent.offsetY;
            } else if (
              (curveTracingModel.prevSnapPoint &&
                curveTracingModel.prevChartX !== mouseEvent.offsetX) ||
              curveTracingModel.prevChartY !== mouseEvent.offsetY
            ) {
              diffX = mouseEvent.offsetX - curveTracingModel.prevChartX;
              diffY = mouseEvent.offsetY - curveTracingModel.prevChartY;
              curveTracingModel.prevSnapPoint
                ? (curveTracingModel.prevSnapPoint[0] =
                    curveTracingModel.prevSnapPoint[0] + diffX)
                : "";
              curveTracingModel.prevSnapPoint
                ? (curveTracingModel.prevSnapPoint[1] =
                    curveTracingModel.prevSnapPoint[1] + diffY)
                : "";

              curveTracingModel.prevChartX = mouseEvent.offsetX;
              curveTracingModel.prevChartY = mouseEvent.offsetY;

              curveTracingModel.prevSnapPoint
                ? (curveTracingModel.prevSnapPoint =
                    this.formDataToGetSnapPoint(
                      curveTracingModel.prevSnapPoint[0],
                      curveTracingModel.prevSnapPoint[1],
                      chartData
                    ))
                : "";
            }

            if (cursor) {
              if (curveTracingModel.prevSnapPoint) {
                cursor.style.display = "block";
                highChart.style.cssText = "cursor:none !important";
                parentDiv.style.cssText = "cursor:none !important";

                xValue = parseFloat(
                  parseFloat(
                    chartData.ref.xAxis[0].toValue(
                      curveTracingModel.prevSnapPoint[0]
                    )
                  ).toFixed(3)
                );
                yValue = parseFloat(
                  parseFloat(
                    chartData.ref.yAxis[0].toValue(
                      curveTracingModel.prevSnapPoint[1]
                    )
                  ).toFixed(3)
                );
                xValue = xValue;
                yValue = yValue;

                xValue >= 1000
                  ? (curveTracingModel.formattedXValue =
                      (xValue / 1000).toFixed(3) + " k")
                  : (curveTracingModel.formattedXValue = xValue);
                curveTracingModel.formattedYValue = yValue;

                curveTracingModel.formattedXValue =
                  curveTracingModel.formattedXValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  );
                curveTracingModel.formattedYValue =
                  curveTracingModel.formattedYValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  );

                if (
                  chartData.ref.xAxis[0].toPixels(xMax) - mouseEvent.offsetX <=
                  tooltip.firstElementChild.clientWidth
                ) {
                  curveTracingModel.tooltipLeftPos =
                    highChart.clientWidth -
                    tooltip.firstElementChild.clientWidth -
                    10;
                } else {
                  curveTracingModel.tooltipLeftPos =
                    curveTracingModel.prevSnapPoint[0] + 5;
                }
                if (
                  tooltip.firstElementChild.clientHeight +
                    (mouseEvent.offsetY - 10) >
                  chartData.ref.xAxis[0].height
                ) {
                  curveTracingModel.tooltipTopPos = mouseEvent.offsetY - 35;
                } else {
                  curveTracingModel.tooltipTopPos =
                    curveTracingModel.prevSnapPoint[1] + 5;
                }
                cursor.style.left =
                  curveTracingModel.prevSnapPoint[0] - 7.5 + "px";
                cursor.style.top =
                  curveTracingModel.prevSnapPoint[1] - 15 + "px";
              } else {
                cursor.style.display = "none";
                highChart.style.cssText = "cursor:block !important";
                parentDiv.style.cssText = "cursor:block !important";
                if (
                  chartData.ref.xAxis[0].toPixels(xMax) - mouseEvent.offsetX <=
                  tooltip.firstElementChild.clientWidth
                ) {
                  curveTracingModel.tooltipLeftPos =
                    highChart.clientWidth -
                    tooltip.firstElementChild.clientWidth -
                    10;
                } else {
                  curveTracingModel.tooltipLeftPos = mouseEvent.offsetX + 5;
                }
                if (
                  tooltip.firstElementChild.clientHeight +
                    (mouseEvent.offsetY - 10) >
                  chartData.ref.xAxis[0].height
                ) {
                  curveTracingModel.tooltipTopPos = mouseEvent.offsetY - 35;
                } else {
                  curveTracingModel.tooltipTopPos = mouseEvent.offsetY + 5;
                }
              }
            }
          }
        } else {
          curveTracingModel.formattedXValue = null;
          curveTracingModel.formattedYValue = null;
          if (cursor) {
            // tooltip.style.cssText = "display : none !important"
            cursor.style.cssText = "display : none !important";
            highChart.style.cssText = "cursor:default !important";
          }
        }
      } else {
        curveTracingModel.formattedXValue = null;
        curveTracingModel.formattedYValue = null;
        if (cursor) {
          // tooltip.style.cssText = "display : none !important"
          cursor.style.cssText = "display : none !important";
          highChart.style.cssText = "cursor:default !important";
        }
      }
    }
    return curveTracingModel;
  }
  formDataToGetSnapPoint(chartX, chartY, chartData) {
    let curvePoints = this.convertSeriesPointIntoArray(chartData);
    let data = {
      pointer: [chartX, chartY],
      grid: {
        x: chartData.ref.xAxis[0].toPixels(
          Math.pow(10, chartData.ref.xAxis[0].min)
        ),
        y: chartData.ref.yAxis[0].toPixels(
          Math.pow(10, chartData.ref.yAxis[0].max)
        ),
        width: chartData.ref.xAxis[0].width,
        height: chartData.ref.xAxis[0].height,
      },
      scale: {
        minX: Math.round(chartData.ref.xAxis[0].min),
        minY: Math.round(chartData.ref.yAxis[0].min),
        maxX: Math.round(chartData.ref.xAxis[0].max),
        maxY: Math.ceil(chartData.ref.yAxis[0].max),
      },
      curves: curvePoints,
    };
    let snapPoint = serviceFactory.FacadeService.getSnapPoint(data);
    return snapPoint;
  }
  convertSeriesPointIntoArray(chartData) {
    let curveData = [];

    chartData.ref.series.forEach((serie) => {
      let pointVal = [];
      serie.data.forEach((point) => {
        pointVal.push([point.x, point.y]);
      });
      curveData.push(pointVal);
    });
    return curveData;
  }
  getTooltipText(curveTracingModel: CurveTracingModel, chartType: ChartType) {
    if (chartType === ChartType.TRIP) {
      return (
        " I = " +
        curveTracingModel.formattedXValue +
        "A / t = " +
        curveTracingModel.formattedYValue +
        " s"
      );
    } else if (chartType === ChartType.CURRENT) {
      return (
        "I = " +
        curveTracingModel.formattedXValue +
        "A / Ic = " +
        curveTracingModel.formattedYValue +
        " A"
      );
    } else {
      return (
        "I = " +
        curveTracingModel.formattedXValue +
        "A / I" +
        toSuperScript("2") +
        "t = " +
        curveTracingModel.formattedYValue +
        " A" +
        toSuperScript("2") +
        "s"
      );
    }
  }

  changeStrokeWidth(
    seriesId: string,
    chartData,
    selectedItemList: ProductModel[],
    selectedViewMarksList: MarkModel[],
    selectedProduct: ProductModel,
    isMarkSelected: boolean
  ) {
    if (chartData && chartData.ref) {
      var seriesLength = chartData.ref.series.length;
      for (var i = 0; i < seriesLength; i++) {
        if (
          chartData.ref.series[i].options.id &&
          seriesId !== chartData.ref.series[i].options.id
        ) {
          if (
            selectedItemList.find(
              (x) => x.product.index === chartData.ref.series[i].options.id
            )
          ) {
            chartData.ref.series[i].options.lineWidth = selectedItemList.find(
              (x) => x.product.index === chartData.ref.series[i].options.id
            ).protectiveDevice.lineWidth;
          } else if (
            selectedViewMarksList.find(
              (y) => y.id === chartData.ref.series[i].options.id
            )
          ) {
            chartData.ref.series[i].options.marker.lineWidth =
              Constants.DEFAULT_MARKWIDTH;
          }
          chartData.ref.series[i].redraw();
        } else if (seriesId === chartData.ref.series[i].options.id) {
          if (
            selectedProduct &&
            selectedProduct.protectiveDevice &&
            !isMarkSelected
          ) {
            chartData.ref.series[i].options.lineWidth =
              selectedProduct.protectiveDevice.lineWidth +
              Constants.HIGHLIGHT_DEVICEWIDTH;
          } else {
            chartData.ref.series[i].options.marker.lineWidth =
              Constants.HIGHLIGHT_MARKWIDTH;
          }

          chartData.ref.series[i].redraw();
        }
      }
      return chartData;
    }
  }
  // changeStrokeWidthMark(
  //   seriesId: number,
  //   chartData,
  //   selectedViewMarksList: MarkModel[]
  // ) {
  //   if (selectedViewMarksList.length != 0) {
  //     if (chartData && chartData.ref) {
  //       var seriesLength = chartData.ref.series.length;
  //       for (var i = 0; i < seriesLength; i++) {
  //         if (
  //           chartData.ref.series[i].options.id &&
  //           seriesId !== chartData.ref.series[i].options.id
  //         ) {
  //           if (
  //             selectedViewMarksList.find(
  //               (x) => x.markListIndex === chartData.ref.series[i].options.id
  //             )
  //           ) {
  //             chartData.ref.series[i].update({
  //               marker: {
  //                 radius: 4,
  //               },
  //             });
  //           }
  //           chartData.ref.series[i].redraw();
  //         } else if (seriesId === chartData.ref.series[i].options.id) {
  //           chartData.ref.series[i].update({
  //             marker: {
  //               radius: 6,
  //             },
  //           });
  //           chartData.ref.series[i].redraw();
  //         }
  //       }
  //     }
  //   }
  // }
  changeSCLWidth(chartData, selectedItemList: ProductModel[]) {
    if (chartData && chartData.ref) {
      var shortCircuitLineLength =
        chartData.ref.xAxis[0].plotLinesAndBands.length;
      for (var i = 0; i < shortCircuitLineLength; i++) {
        let selectedProduct = selectedItemList.find(
          (x) =>
            x.product.index ===
            chartData.ref.xAxis[0].plotLinesAndBands[i].options.id
        );
        if (selectedProduct) {
          chartData.ref.xAxis[0].plotLinesAndBands[i].options.width =
            selectedProduct.protectiveDevice.lineWidth;
        }

        chartData.ref.xAxis[0].update();
      }
      return chartData;
    }
  }
  removeSeries(id: string, chartData: any) {
    if (chartData && chartData.ref) {
      var seriesLength = chartData.ref.series.length;

      for (var i = seriesLength - 1; i > -1; i--) {
        if (id == chartData.ref.series[i].options.id) {
          chartData.ref.series[i].remove();
        }
      }
      return chartData;
    }
  }
  removeAllSeries(chartData: any) {
    if (chartData && chartData.ref) {
      var seriesLength = chartData.ref.series.length;
      for (var i = seriesLength - 1; i > -1; i--) {
        chartData.ref.series[i].remove();
      }
      return chartData;
    }
  }
  showElement(item: BaseModel, chartData, isShowDefaultText: boolean) {
    var seriesLength = chartData.ref.series.length;
    isShowDefaultText = false;
    for (var i = 0; i < seriesLength; i++) {
      if (item.index == chartData.ref.series[i].options.id) {
        chartData.ref.series[i].setVisible(true);
      }
    }
    return isShowDefaultText;
  }
  hideElement(item: BaseModel, chartData, isShowDefaultText: boolean) {
    var seriesLength = chartData.ref.series.length;
    let isEmptySeriesExist = seriesLength % 2 === 1 ? true : false;
    let seriesVisibleCount = isEmptySeriesExist ? 1 : 0;
    for (var i = 0; i < seriesLength; i++) {
      if (item.index == chartData.ref.series[i].options.id) {
        chartData.ref.series[i].setVisible(false);
        seriesVisibleCount++;
      } else {
        if (!chartData.ref.series[i].visible) {
          seriesVisibleCount++;
        }
      }
      if (
        seriesVisibleCount === seriesLength &&
        chartData.ref.xAxis[0].plotLinesAndBands.length === 0
      ) {
        isShowDefaultText = true;
      }
    }
    return isShowDefaultText;
  }
  changeLineStyle(lineConfig: LineConfigurationModel, chartData) {
    if (chartData && chartData.ref) {
      var seriesLength = chartData.ref.series.length;
      for (var i = 0; i < seriesLength; i++) {
        if (lineConfig.productIndex === chartData.ref.series[i].options.id) {
          if (lineConfig.lineColor) {
            chartData.ref.series[i].color = lineConfig.lineColor;
            chartData.ref.series[i].options.color = lineConfig.lineColor;
            chartData.ref.series[i].userOptions.color = lineConfig.lineColor;
            // chartData.ref.series[i].update({
            //   color: lineConfig.lineColor,
            // });
          }
          if (lineConfig.lineStyle) {
            chartData.ref.series[i].options.dashStyle = lineConfig.lineStyle;
            chartData.ref.series[i].userOptions.dashStyle =
              lineConfig.lineStyle;

            // chartData.ref.series[i].update({
            //   dashStyle: lineConfig.lineStyle,
            // });
          }
          if (lineConfig.lineWidth) {
            chartData.ref.series[i].options.lineWidth = lineConfig.lineWidth;
            chartData.ref.series[i].userOptions.lineWidth =
              lineConfig.lineWidth;
            // chartData.ref.series[i].update({
            //   lineWidth: lineConfig.lineWidth,
            // });
          }
          // chartData.ref.series[i].isDirtyLegend = true
          chartData.ref.series[i].update();
        }
      }
      return chartData;
    }
  }
  showHideMark(markModel: MarkModel, chartData) {
    var seriesLength = chartData.ref.series.length;
    for (var i = 0; i < seriesLength; i++) {
      if (markModel.id == chartData.ref.series[i].options.id) {
        chartData.ref.series[i].setVisible(markModel.visible);
      }
    }
    return chartData;
  }

  changeMarkColor(markModel: MarkModel, chartData) {
    var seriesLength = chartData.ref.series.length;
    const lineWidth = markModel.isChecked
      ? Constants.HIGHLIGHT_MARKWIDTH
      : Constants.DEFAULT_MARKWIDTH;
    for (var i = seriesLength - 1; i > -1; i--) {
      if (markModel.id === chartData.ref.series[i].options.id) {
        chartData.ref.series[i].update({
          marker: {
            lineColor: markModel.rgbValue,
            lineWidth: lineWidth,
          },
          dataLabels: {
            color: markModel.rgbValue,
            lineColor: markModel.rgbValue,
            borderColor: markModel.rgbValue,
          },
        });
      }
    }
    return chartData;
  }
  findNearestCurve(
    chartType: ChartType,
    curveTracingModel: CurveTracingModel,
    chartData: any,
    selectedItemList: ProductModel[]
  ) {
    let chart;
    if (chartType === ChartType.TRIP) {
      chart = MarkConstants.FACADE_TRIP;
    } else if (chartType === ChartType.CURRENT) {
      chart = MarkConstants.FACADE_CURRENT;
    } else {
      chart = MarkConstants.FACADE_ENERGY;
    }
    let data = {
      //FIXME: Accessing these props throws an error on the console.
      pointer: [
        curveTracingModel.prevSnapPoint[0],
        curveTracingModel.prevSnapPoint[1],
      ],
      grid: {
        x: chartData.ref.xAxis[0].toPixels(
          Math.pow(10, chartData.ref.xAxis[0].min)
        ),
        y: chartData.ref.yAxis[0].toPixels(
          Math.pow(10, chartData.ref.yAxis[0].max)
        ),
        width: chartData.ref.xAxis[0].width,
        height: chartData.ref.xAxis[0].height,
      },
      scale: {
        minX: Math.round(chartData.ref.xAxis[0].min),
        minY: Math.round(chartData.ref.yAxis[0].min),
        maxX: Math.round(chartData.ref.xAxis[0].max),
        maxY: Math.ceil(chartData.ref.yAxis[0].max),
      },
      curveType: chart,
      productModels: selectedItemList,
    };

    return serviceFactory.FacadeService.getNearestCurve(data);
  }
  findCorrespondingXvalue(curveDetails: CurveDetails, yValue: string) {
    return serviceFactory.FacadeService.getXvalue(curveDetails, yValue);
  }
  findCorrespondingYvalue(curveDetails: CurveDetails, xValue: string) {
    return serviceFactory.FacadeService.getYvalue(curveDetails, xValue);
  }
}
