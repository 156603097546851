import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CommonHelperModule } from "../common/common-helper.module";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { ProjectSidebarComponent } from "../projects/list/project-sidebar/project-sidebar.component";
import { ProjectHeaderComponent } from "./project-header/project-header.component";
@NgModule({
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectSidebarComponent,
    ProjectHeaderComponent,
  ],
  imports: [
    CommonHelperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectSidebarComponent,
    ProjectHeaderComponent,
  ],
  providers: [],
  bootstrap: [],
})
export class ProjectsModule {}
