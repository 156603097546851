import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { ButtonComponent } from "../../../packages/ui/src/primitives/button/button.component";
import { Subscription } from "rxjs";
import { InputComponent } from "../../../packages/ui/src/primitives/input/input.component";
import { TooltipDirective } from "../common/tooltip.directive";
import { EventType } from "../models/constants/eventType";
import { MarkViewType } from "../models/constants/mark-view-type";
import { MarkConstants } from "../models/constants/MarkConstants";
import MarkModel from "../models/marks/MarkModel";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { CloseIconComponent } from "../icons/close-icon/close-icon.component";
import { LinkIconComponent } from "../icons/link-icon/link-icon.component";
import { ValueModifierDirective } from "../lib/pipes/localize";

@Component({
  selector: "app-add-mark-component",
  templateUrl: "./add-mark-component.component.html",
  styleUrls: ["./add-mark-component.component.css"],
  standalone: true,
  imports: [
    TooltipDirective,
    FormsModule,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    CloseIconComponent,
    LinkIconComponent,
    ReactiveFormsModule,
    ValueModifierDirective,
  ],
})
export class AddMarkComponentComponent implements OnDestroy, OnInit {
  xLabel: string = MarkConstants.MARK_XLABEL;
  yLabel: string = MarkConstants.MARK_YLABEL;
  subscriptions: Subscription = new Subscription();
  isShowLinkUnlink: boolean = true;
  tooltipXVal: string;
  tooltipYVal: string;
  markForm = new FormGroup({
    currentValue: new FormControl(this.data.translatedCurrentValue, [
      Validators.required,
      Validators.min(MarkConstants.MIN_IA),
      Validators.max(MarkConstants.MAX_IA),
    ]),
    timeValue: new FormControl(this.data.translatedTimeValue, [
      Validators.required,
      this.timeValueValidator(),
    ]),
    isLinked: new FormControl(false),
  });

  constructor(
    public dialogRef: MatDialogRef<AddMarkComponentComponent>,
    public eventBus: NgEventBus,
    @Inject(MAT_DIALOG_DATA) public data: MarkModel
  ) {
    /** Handle current value changes. */
    this.subscriptions.add(
      this.eventBus
        .on(EventType.UPDATED_VALUE)
        .subscribe((metadata: MetaData<{ x?: number; y?: number }>) => {
          if (metadata.data.y) {
            this.markForm.patchValue({
              timeValue: metadata.data.y.toString(),
            });
          }
        })
    );

    /** Handle time changes. */
    this.subscriptions.add(
      this.eventBus
        .on(EventType.UPDATED_VALUE)
        .subscribe((metadata: MetaData<{ x?: number; y?: number }>) => {
          if (metadata.data.x) {
            this.markForm.patchValue({
              currentValue: metadata.data.x.toString(),
            });
          }
        })
    );
  }

  ngOnInit() {
    this.tooltipXVal = serviceFactory.MarksHelper.getFormattedTooltip(
      MarkConstants.MIN_IA,
      MarkConstants.MAX_IA
    );
    this.tooltipYVal = serviceFactory.MarksHelper.getTooltip(this.data.view);
    this.yLabel = serviceFactory.MarksHelper.getYLabel(this.data.view);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit() {
    this.dialogRef.close(this.data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  timeValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseFloat(control.value);
      switch (this.data.view) {
        case MarkViewType.TRIP:
          return value >= MarkConstants.MIN_Ts && value <= MarkConstants.MAX_Ts
            ? null
            : { invalidTimeValue: "Time value is invalid." };
        case MarkViewType.CURRENT:
          return value >= MarkConstants.MIN_IC && value <= MarkConstants.MAX_IC
            ? null
            : { invalidTimeValue: "Time value is invalid." };
        case MarkViewType.ENERGY:
          return value >= MarkConstants.MIN_I2t &&
            value <= MarkConstants.MAX_I2t
            ? null
            : { invalidTimeValue: "Time value is invalid." };
        default:
          return { invalidTimeValue: "Time value is invalid." };
      }
    };
  }

  updatedTime(value: string) {
    const changedValue = serviceFactory.MarksHelper.convertLocaleNumber(
      value,
      localStorage.getItem("i18nextLng")
    );
    if (this.markForm.value.isLinked && this.markForm.valid) {
      this.eventBus.cast(EventType.LINK_MARK_VALUE_CHANGEX, changedValue);
    } else if (changedValue) {
      this.data.current = changedValue;
    }
  }

  updateCurrent(value: string) {
    const changedValue = serviceFactory.MarksHelper.convertLocaleNumber(
      value,
      localStorage.getItem("i18nextLng")
    );
    if (this.markForm.value.isLinked && this.markForm.valid) {
      this.eventBus.cast(EventType.LINK_MARK_VALUE_CHANGEY, changedValue);
    } else if (changedValue) {
      this.data.time = changedValue;
    }
  }
}
