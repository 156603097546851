import {
  Provider,
  forwardRef,
  Input,
  Directive,
  ElementRef,
  HostListener,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const MODIFIER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ValueModifierDirective),
  multi: true,
};
/**
 * Custom pipe that adds a keyup listener and proxies input events making it possible to transform user inputs.
 * Works in both directions, viewToForm and formToView. Uses the locale from localStorage.getItem("i18nextLng").
 *
 * @example
 * <input appLocalize />
 */
@Directive({
  selector: "[appLocalize]",
  standalone: true,
  providers: [MODIFIER_CONTROL_VALUE_ACCESSOR],
})
export class ValueModifierDirective implements ControlValueAccessor {
  @Input() valueModifier: [Function, Function];
  @HostListener("keyup", ["$event.currentTarget.value"])
  doSomething(value: string) {
    console.log(value);
    this.writeToForm(this.viewToForm(value));
  }

  viewToForm = (text: string) =>
    convertLocaleNumber(text, localStorage.getItem("i18nextLng"));
  formToView = (text: string) =>
    Number(text).toLocaleString(localStorage.getItem("i18nextLng"));

  private writeToForm;

  constructor(public _el: ElementRef) {}

  registerOnChange(fn: (value: any) => void) {
    this.writeToForm = fn;
  }

  registerOnTouched(fn: any) {
    // nothing to do
  }

  writeValue(value: any) {
    this._el.nativeElement.value = this.formToView(value);
  }
}

export function convertLocaleNumber(
  num: string,
  locale: string
): number | undefined {
  const { format } = new Intl.NumberFormat(locale);
  const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
  const validNumberPattern = new RegExp(`^[\\d${decimalSign}]+$`);
  if (!validNumberPattern.test(num)) {
    return undefined;
  }
  try {
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");
  } catch (error) {
    console.log(error);
    return undefined;
  }
}
