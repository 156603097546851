import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import { EventType } from "../../models/constants/eventType";
import RelaySetting from "../../models/device-settings/DeviceRelaySettingModel";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import SettingsSliderModel from "../../models/device-settings/DeviceSettingsSliderModel";
import BaseModel from "../../models/devices/BaseModel";
import ProductModel from "../../models/devices/ProductModel";
import MarkModel from "../../models/marks/MarkModel";
import ProtectiveDevice from "../../services/../models/devices/protectiveDevice";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { ToasterService } from "../../services/toaster.service";
import { TranslateModule } from "@ngx-translate/core";
import { SettingsSclMarkComponent } from "../settings-scl-mark/settings-scl-mark.component";
import { SettingHorizontalSliderComponent } from "../setting-horizontal-slider/setting-horizontal-slider.component";
import { SettingRangeComponent } from "../setting-range/setting-range.component";
import { SettingInputComponent } from "../setting-input/setting-input.component";
import { SettingRadioComponent } from "../setting-radio/setting-radio.component";
import { SettingCheckComponent } from "../setting-check/setting-check.component";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
} from "@angular/material/expansion";
import { SettingDropdownComponent } from "../setting-dropdown/setting-dropdown.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { ButtonComponent } from "../../../../packages/ui/src/primitives/button/button.component";
@Component({
  selector: "app-device-settings-bar",
  templateUrl: "./device-settings-bar.component.html",
  styleUrls: ["./device-settings-bar.component.css"],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    SettingDropdownComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    SettingCheckComponent,
    MatExpansionPanelDescription,
    SettingRadioComponent,
    SettingInputComponent,
    SettingRangeComponent,
    NgClass,
    SettingHorizontalSliderComponent,
    SettingsSclMarkComponent,
    TranslateModule,
    ButtonComponent,
  ],
})
export class DeviceSettingsBarComponent implements OnDestroy, OnInit {
  @Input() selectedProduct: BaseModel;

  relaySetting = new Array<RelaySetting>();
  subscriptions: Subscription = new Subscription();
  itemIndexToSettingsArrayMap = new Map<string, Array<DeviceSettingsModel>>();
  settingsRange: Array<DeviceSettingsModel> = new Array<DeviceSettingsModel>();
  selectedProductData: ProductModel;
  selectedSettingObject: DeviceSettingsModel = new DeviceSettingsModel();
  driveProjectId: string;
  markmodel: MarkModel = new MarkModel();
  isMarkSelected: boolean = false;
  constructor(
    public eventBus: NgEventBus,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    public route: Router
  ) {
    const settingSub = this.eventBus
      .on(EventType.ON_SETTINGS_ICON_CLICK)
      .subscribe(async (metadata: MetaData) => {
        this.isMarkSelected = false;
        let item: ProductModel = metadata.data;
        this.selectedProductData = item;
        this.selectedProduct = item.product;
        this.getRelaySetting(item);
        if (this.itemIndexToSettingsArrayMap.get(item.product.index)) {
          this.settingsRange = this.itemIndexToSettingsArrayMap.get(
            item.product.index
          );
        } else {
          this.getSettingsObject(item);
        }
      });
    const EMIT_SELECTED_MARK = this.eventBus
      .on(EventType.EMITSELECTEDMARK)
      .subscribe((metaData: MetaData) => {
        this.markmodel = metaData.data;
        this.relaySetting = undefined;
        this.settingsRange = [];
        this.isMarkSelected = true;
      });
    const onEditProduct = this.eventBus
      .on(EventType.CHARTDATATOBEUPDATED)
      .subscribe(async (metadata: MetaData) => {
        let item: ProductModel = metadata.data;
        this.selectedProductData = item;
        this.selectedProduct = item.product;
        this.getRelaySetting(item);
        this.getSettingsObject(item);
      });

    const onDeletePrdSub = this.eventBus
      .on(EventType.CONFIRM_DELETE_PRODUCT)
      .subscribe((metadata: MetaData) => {
        let item: BaseModel = metadata.data;
        if (item) {
          // if (item.index === this.selectedProduct.index) {
          this.itemIndexToSettingsArrayMap.delete(this.selectedProduct.index);
          this.settingsRange = [];
          this.selectedProduct = new BaseModel();
          this.relaySetting = new Array<RelaySetting>();
          // }
        }
      });
    // const onDeleteMark = this.eventBus
    //   .on(EventType.DELETE_MARK_BE)
    //   .subscribe((metadata: MetaData) => {
    //     if (metadata.data) {
    //       this.xLabel = "";
    //       this.yLabel = "";
    //     }
    //   });
    const onChecked = this.eventBus
      .on(EventType.EMIT_CHECKED_EVENT)
      .subscribe((metadata: MetaData) => {
        let setting: DeviceSettingsModel = metadata.data;
        for (let i = 0; i < this.settingsRange.length; i++) {
          if (this.settingsRange[i].curveType === setting.curveType) {
            this.settingsRange[i].isEngaged = setting.isEngaged;
          }
        }
      });
    const onRadioEvent = this.eventBus
      .on(EventType.EMIT_RADIO_EVENT)
      .subscribe((metadata: MetaData) => {
        let settingObject: DeviceSettingsModel = metadata.data;
        this.selectedSettingObject = settingObject;
        if (
          this.itemIndexToSettingsArrayMap.get(
            settingObject.productDetails.index
          )
        ) {
          this.itemIndexToSettingsArrayMap
            .get(settingObject.productDetails.index)
            .find(
              (x) => x.curveType === settingObject.curveType
            ).activeCharacteristic = settingObject.selectedCharacteristic;
        }
      });
    const updateSliderValueEvent = this.eventBus
      .on(EventType.UPDATE_SLIDER_VALUE)
      .subscribe(async (metadata: MetaData) => {
        let device: ProtectiveDevice = metadata.data;
        await serviceFactory.FacadeService.updateSettingObject(
          this.selectedSettingObject,
          device,
          this.selectedProduct
        )
          .then((settingsValue: DeviceSettingsModel) => {
            let index = this.settingsRange.findIndex(
              (x) => x.curveType === settingsValue.curveType
            );
            if (settingsValue.currentRef) {
              this.settingsRange[index].currentRef = settingsValue.currentRef;
            } else {
              this.settingsRange[index].currentRef = new SettingsSliderModel();
            }
            settingsValue.currentTime.forEach((slider: SettingsSliderModel) => {
              let isExist = this.settingsRange[index].currentTime.findIndex(
                (x) => x.inputLabel === slider.inputLabel
              );
              this.updateSliderValue(settingsValue, index);
            });
          })
          .catch((error: AxiosError) => {
            this.toasterService.showError(error.message);
          });
      });

    const updateAllPartTypeAfterSliderChange = this.eventBus
      .on(EventType.UPDATESETTINGAFTERSLIDERCHANGE)
      .subscribe(async (meta: MetaData) => {
        let device: ProtectiveDevice = meta.data;
        await serviceFactory.FacadeService.getSettingsObject(
          this.selectedProductData.product,
          device
        ).then((setting) => {
          setting.forEach((settingsValue) => {
            let index = this.settingsRange.findIndex(
              (x) => x.curveType === settingsValue.curveType
            );
            this.updateSliderValue(settingsValue, index);
          });
        });
      });
    // const tabChange = this.eventBus
    //   .on(EventType.TAB_CHANGE)
    //   .subscribe((metaData: MetaData) => {
    //     this.tabChange = metaData.data;
    //   });

    // const updateMarkValue = this.eventBus
    //   .on(EventType.UPDATE_MARK_VALUE)
    //   .subscribe((metaData: MetaData) => {
    //     let updatedMark: MarkModel = metaData.data;
    //     this.onMarkValueChange(updatedMark);
    //   });
    // const settingInputMarkCurrent = this.eventBus
    //   .on(EventType.SETTINGINPUTMARKCURRENT)
    //   .subscribe((metaData: MetaData) => {
    //     if (metaData.data) {
    //       this.onValueChangeCurrent(metaData.data);
    //     }
    //   });
    this.subscriptions.add(settingSub);
    this.subscriptions.add(onDeletePrdSub);
    this.subscriptions.add(onRadioEvent);
    this.subscriptions.add(updateSliderValueEvent);
    this.subscriptions.add(onChecked);
    this.subscriptions.add(onEditProduct);
    this.subscriptions.add(updateAllPartTypeAfterSliderChange);
    this.subscriptions.add(EMIT_SELECTED_MARK);
    // this.subscriptions.add(onDeleteMark);
    // this.subscriptions.add(updateMarkValue);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      this.driveProjectId = param.id;
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  async getRelaySetting(item: ProductModel) {
    await serviceFactory.FacadeService.getRelaySettings(item)
      .then((res) => {
        this.relaySetting = res;
      })
      .catch((error) => {
        this.relaySetting = [];
        this.toasterService.showError(error.message);
      });
  }
  async getSettingsObject(item: ProductModel) {
    await serviceFactory.FacadeService.getSettingsObject(
      item.product,
      item.protectiveDevice
    )
      .then((settingsValue: Array<DeviceSettingsModel>) => {
        this.itemIndexToSettingsArrayMap.set(item.product.index, settingsValue);
        this.settingsRange = settingsValue;
      })
      .catch((error: AxiosError) => {
        this.toasterService.showError(error.message);
      });
  }
  async onESETClick(settingValue: SettingsSliderModel) {
    await serviceFactory.FacadeService.onESetClick(
      settingValue,
      this.selectedProductData.protectiveDevice,
      this.selectedProductData.product
    )
      .then(async (device: ProtectiveDevice) => {
        this.selectedSettingObject.curveType = settingValue.curveType;
        await serviceFactory.FacadeService.updateSettingObject(
          this.selectedSettingObject,
          device,
          this.selectedProduct
        ).then((sliderObject: DeviceSettingsModel) => {
          let index = this.settingsRange.findIndex(
            (x) => x.curveType === sliderObject.curveType
          );
          let controlTypeIndex = this.settingsRange[
            index
          ].currentTime.findIndex((x) => x.type === settingValue.type);
          let responseIndex = sliderObject.currentTime.findIndex(
            (x) => x.type === settingValue.type
          );
          this.settingsRange[index].currentTime[controlTypeIndex] =
            sliderObject.currentTime[responseIndex];
          let data: DeviceSettingsModel = new DeviceSettingsModel();
          data.productDetails = sliderObject.productDetails;
          data.currentTime.push(sliderObject.currentTime[responseIndex]);
          this.eventBus.cast(EventType.EMITINPUTEVENT, data);
        });
      })
      .catch((error: AxiosError) => {
        this.toasterService.showError(error.message);
      });
  }
  updateSliderValue(settingsValue, index) {
    settingsValue.currentTime.forEach((slider: SettingsSliderModel) => {
      let isExist = this.settingsRange[index].currentTime.findIndex(
        (x) => x.inputLabel === slider.inputLabel
      );
      if (isExist >= 0) {
        this.settingsRange[index].currentTime[isExist].minValue =
          slider.minValue;
        this.settingsRange[index].currentTime[isExist].maxValue =
          slider.maxValue;
        this.settingsRange[index].currentTime[isExist].steps = slider.steps;
        this.settingsRange[index].currentTime[isExist].inputValue =
          slider.inputValue;
        this.settingsRange[index].currentTime[isExist].translatedMinValue =
          slider.minValue
            ? slider.minValue.toLocaleString(localStorage.getItem("i18nextLng"))
            : null;
        this.settingsRange[index].currentTime[isExist].translatedMaxValue =
          slider.maxValue
            ? slider.maxValue.toLocaleString(localStorage.getItem("i18nextLng"))
            : null;
        this.settingsRange[index].currentTime[isExist].translatedInputValue =
          slider.inputValue
            ? slider.inputValue.toLocaleString(
                localStorage.getItem("i18nextLng")
              )
            : null;
        this.settingsRange[index].currentTime[isExist].inputIndex =
          slider.inputIndex;
        this.settingsRange[index].currentTime[isExist].controlTypeList =
          slider.controlTypeList;
        this.settingsRange[index].currentTime[isExist].controlType =
          slider.controlType;
      }
    });
  }
}
